import React from 'react'

const Logo = () => (
  <svg viewBox="0 0 64 64">
      <g className="st0" id="Layer_1"/>
      <g className="st0" id="Layer_4">
          <rect className="st1" height="524" width="524" x="-440" y="-20"/>
      </g>
      <g className="st0" id="Filled">
          <g className="st2">
              <circle className="st24" cx="32" cy="32" r="17"/>
              <g>
                  <circle className="st41" cx="57" cy="32" r="3"/>
                  <circle className="st41" cx="7" cy="32" r="3"/>
                  <line className="st22" x1="49" x2="54" y1="32" y2="32"/>
                  <line className="st22" x1="10" x2="15" y1="32" y2="32"/>
              </g>
              <g>
                  <circle className="st41" cx="53.65" cy="19.5" r="3"/>
                  <circle className="st41" cx="10.35" cy="44.5" r="3"/>
                  <line className="st22" x1="46.72" x2="51.05" y1="23.5" y2="21"/>
                  <line className="st22" x1="12.95" x2="17.28" y1="43" y2="40.5"/>
              </g>
              <g>
                  <circle className="st41" cx="44.5" cy="10.35" r="3"/>
                  <circle className="st41" cx="19.5" cy="53.65" r="3"/>
                  <line className="st22" x1="40.5" x2="43" y1="17.28" y2="12.95"/>
                  <line className="st22" x1="21" x2="23.5" y1="51.05" y2="46.72"/>
              </g>
              <g>
                  <circle className="st41" cx="32" cy="7" r="3"/>
                  <circle className="st41" cx="32" cy="57" r="3"/>
                  <line className="st22" x1="32" x2="32" y1="15" y2="10"/>
                  <line className="st22" x1="32" x2="32" y1="54" y2="49"/>
              </g>
              <g>
                  <circle className="st41" cx="19.5" cy="10.35" r="3"/>
                  <circle className="st41" cx="44.5" cy="53.65" r="3"/>
                  <line className="st22" x1="23.5" x2="21" y1="17.28" y2="12.95"/>
                  <line className="st22" x1="43" x2="40.5" y1="51.05" y2="46.72"/>
              </g>
              <g>
                  <circle className="st41" cx="10.35" cy="19.5" r="3"/>
                  <circle className="st41" cx="53.65" cy="44.5" r="3"/>
                  <line className="st22" x1="17.28" x2="12.95" y1="23.5" y2="21"/>
                  <line className="st22" x1="51.05" x2="46.72" y1="43" y2="40.5"/>
              </g>
              <circle className="st41" cx="42.5" cy="32" r="2.5"/>
              <circle className="st41" cx="21.5" cy="32" r="2.5"/>
              <circle className="st41" cx="37.25" cy="22.91" r="2.5"/>
              <circle className="st41" cx="26.75" cy="41.09" r="2.5"/>
              <circle className="st41" cx="26.75" cy="22.91" r="2.5"/>
              <circle className="st41" cx="37.25" cy="41.09" r="2.5"/>
              <circle className="st41" cx="32" cy="32" r="3"/>
          </g>
      </g>
      <g className="st0" id="Flat">
          <g className="st2">
              <g>
                  <path className="st59"
                        d="M32,47c-0.55,0-1,0.45-1,1v7c0,0.55,0.45,1,1,1s1-0.45,1-1v-7C33,47.45,32.55,47,32,47z"/>
                  <g>
                      <path className="st60" d="M32,47v9c0.55,0,1-0.45,1-1v-7C33,47.45,32.55,47,32,47z"/>
                  </g>
                  <circle className="st61" cx="32" cy="57" r="3"/>
                  <path className="st62" d="M32,54v6c1.66,0,3-1.34,3-3S33.66,54,32,54z"/>
              </g>
              <g>
                  <path className="st59"
                        d="M32,17c-0.55,0-1-0.45-1-1V9c0-0.55,0.45-1,1-1s1,0.45,1,1v7C33,16.55,32.55,17,32,17z"/>
                  <path className="st60" d="M32,8v9c0.55,0,1-0.45,1-1V9C33,8.45,32.55,8,32,8z"/>
                  <circle className="st61" cx="32" cy="7" r="3"/>
                  <g>
                      <g>
                          <path className="st62" d="M32,4v6c1.66,0,3-1.34,3-3S33.66,4,32,4z"/>
                      </g>
                  </g>
              </g>
              <g>
                  <path className="st59"
                        d="M24.5,19.01c-0.48,0.28-1.09,0.11-1.37-0.37l-3.5-6.06c-0.28-0.48-0.11-1.09,0.37-1.37s1.09-0.11,1.37,0.37     l3.5,6.06C25.14,18.12,24.98,18.73,24.5,19.01z"/>
                  <circle className="st61" cx="19.5" cy="10.35" r="3"/>
              </g>
              <g>
                  <path className="st60"
                        d="M39.5,44.99c-0.48,0.28-0.64,0.89-0.37,1.37l3.5,6.06c0.28,0.48,0.89,0.64,1.37,0.37s0.64-0.89,0.37-1.37     l-3.5-6.06C40.59,44.88,39.98,44.71,39.5,44.99z"/>
                  <circle className="st62" cx="44.5" cy="53.65" r="3"/>
              </g>
              <g>
                  <path className="st59"
                        d="M19.01,24.5c-0.28,0.48-0.89,0.64-1.37,0.37l-6.06-3.5c-0.48-0.28-0.64-0.89-0.37-1.37s0.89-0.64,1.37-0.37     l6.06,3.5C19.12,23.41,19.29,24.02,19.01,24.5z"/>
                  <circle className="st61" cx="10.35" cy="19.5" r="3"/>
              </g>
              <g>
                  <path className="st60"
                        d="M44.99,39.5c-0.28,0.48-0.11,1.09,0.37,1.37l6.06,3.5c0.48,0.28,1.09,0.11,1.37-0.37s0.11-1.09-0.37-1.37     l-6.06-3.5C45.88,38.86,45.27,39.02,44.99,39.5z"/>
                  <circle className="st62" cx="53.65" cy="44.5" r="3"/>
              </g>
              <g>
                  <path className="st59"
                        d="M17,32c0,0.55-0.45,1-1,1H9c-0.55,0-1-0.45-1-1s0.45-1,1-1h7C16.55,31,17,31.45,17,32z"/>
                  <circle className="st61" cx="7" cy="32" r="3"/>
              </g>
              <g>
                  <path className="st60"
                        d="M47,32c0,0.55,0.45,1,1,1h7c0.55,0,1-0.45,1-1s-0.45-1-1-1h-7C47.45,31,47,31.45,47,32z"/>
                  <circle className="st62" cx="57" cy="32" r="3"/>
              </g>
              <g>
                  <path className="st59"
                        d="M19.01,39.5c0.28,0.48,0.11,1.09-0.37,1.37l-6.06,3.5c-0.48,0.28-1.09,0.11-1.37-0.37s-0.11-1.09,0.37-1.37     l6.06-3.5C18.12,38.86,18.73,39.02,19.01,39.5z"/>
                  <circle className="st61" cx="10.35" cy="44.5" r="3"/>
              </g>
              <g>
                  <path className="st60"
                        d="M44.99,24.5c0.28,0.48,0.89,0.64,1.37,0.37l6.06-3.5c0.48-0.28,0.64-0.89,0.37-1.37s-0.89-0.64-1.37-0.37     l-6.06,3.5C44.88,23.41,44.71,24.02,44.99,24.5z"/>
                  <circle className="st62" cx="53.65" cy="19.5" r="3"/>
              </g>
              <g>
                  <path className="st59"
                        d="M24.5,44.99c0.48,0.28,0.64,0.89,0.37,1.37l-3.5,6.06c-0.28,0.48-0.89,0.64-1.37,0.37s-0.64-0.89-0.37-1.37     l3.5-6.06C23.41,44.88,24.02,44.71,24.5,44.99z"/>
                  <circle className="st61" cx="19.5" cy="53.65" r="3"/>
              </g>
              <g>
                  <path className="st60"
                        d="M39.5,19.01c0.48,0.28,1.09,0.11,1.37-0.37l3.5-6.06c0.28-0.48,0.11-1.09-0.37-1.37s-1.09-0.11-1.37,0.37     l-3.5,6.06C38.86,18.12,39.02,18.73,39.5,19.01z"/>
                  <circle className="st62" cx="44.5" cy="10.35" r="3"/>
              </g>
              <circle className="st61" cx="32" cy="32" r="17"/>
              <g>
                  <path className="st62" d="M32,15v34c9.39,0,17-7.61,17-17S41.39,15,32,15z"/>
              </g>
              <circle className="st60" cx="42.5" cy="32" r="2.5"/>
              <circle className="st59" cx="21.5" cy="32" r="2.5"/>
              <circle className="st60" cx="37.25" cy="22.91" r="2.5"/>
              <circle className="st59" cx="26.75" cy="41.09" r="2.5"/>
              <circle className="st59" cx="26.75" cy="22.91" r="2.5"/>
              <circle className="st60" cx="37.25" cy="41.09" r="2.5"/>
              <circle className="st59" cx="32" cy="32" r="3"/>
              <g>
                  <path className="st60" d="M32,29v6c1.66,0,3-1.34,3-3S33.66,29,32,29z"/>
              </g>
          </g>
      </g>
      <g className="st0" id="Line">
          <g className="st2">
              <circle className="st22" cx="32" cy="32" r="17"/>
              <g>
                  <circle className="st22" cx="57" cy="32" r="3"/>
                  <circle className="st22" cx="7" cy="32" r="3"/>
                  <line className="st22" x1="49" x2="54" y1="32" y2="32"/>
                  <line className="st22" x1="10" x2="15" y1="32" y2="32"/>
              </g>
              <g>
                  <circle className="st22" cx="53.65" cy="19.5" r="3"/>
                  <circle className="st22" cx="10.35" cy="44.5" r="3"/>
                  <line className="st22" x1="46.72" x2="51.05" y1="23.5" y2="21"/>
                  <line className="st22" x1="12.95" x2="17.28" y1="43" y2="40.5"/>
              </g>
              <g>
                  <circle className="st22" cx="44.5" cy="10.35" r="3"/>
                  <circle className="st22" cx="19.5" cy="53.65" r="3"/>
                  <line className="st22" x1="40.5" x2="43" y1="17.28" y2="12.95"/>
                  <line className="st22" x1="21" x2="23.5" y1="51.05" y2="46.72"/>
              </g>
              <g>
                  <circle className="st22" cx="32" cy="7" r="3"/>
                  <circle className="st22" cx="32" cy="57" r="3"/>
                  <line className="st22" x1="32" x2="32" y1="15" y2="10"/>
                  <line className="st22" x1="32" x2="32" y1="54" y2="49"/>
              </g>
              <g>
                  <circle className="st22" cx="19.5" cy="10.35" r="3"/>
                  <circle className="st22" cx="44.5" cy="53.65" r="3"/>
                  <line className="st22" x1="23.5" x2="21" y1="17.28" y2="12.95"/>
                  <line className="st22" x1="43" x2="40.5" y1="51.05" y2="46.72"/>
              </g>
              <g>
                  <circle className="st22" cx="10.35" cy="19.5" r="3"/>
                  <circle className="st22" cx="53.65" cy="44.5" r="3"/>
                  <line className="st22" x1="17.28" x2="12.95" y1="23.5" y2="21"/>
                  <line className="st22" x1="51.05" x2="46.72" y1="43" y2="40.5"/>
              </g>
              <circle className="st22" cx="42.5" cy="32" r="2.5"/>
              <circle className="st22" cx="21.5" cy="32" r="2.5"/>
              <circle className="st22" cx="37.25" cy="22.91" r="2.5"/>
              <circle className="st22" cx="26.75" cy="41.09" r="2.5"/>
              <circle className="st22" cx="26.75" cy="22.91" r="2.5"/>
              <circle className="st22" cx="37.25" cy="41.09" r="2.5"/>
              <circle className="st22" cx="32" cy="32" r="3"/>
          </g>
      </g>
      <g id="Solid">
          <g>
              <g>
                  <g>
                      <g>
                          <circle cx="57" cy="32" r="3"/>
                      </g>
                      <g>
                          <circle cx="7" cy="32" r="3"/>
                      </g>
                      <g>
                          <path d="M55,33h-7c-0.55,0-1-0.45-1-1s0.45-1,1-1h7c0.55,0,1,0.45,1,1S55.55,33,55,33z"/>
                      </g>
                      <g>
                          <path d="M16,33H9c-0.55,0-1-0.45-1-1s0.45-1,1-1h7c0.55,0,1,0.45,1,1S16.55,33,16,33z"/>
                      </g>
                  </g>
                  <g>
                      <g>
                          <circle cx="53.65" cy="19.5" r="3"/>
                      </g>
                      <g>
                          <circle cx="10.35" cy="44.5" r="3"/>
                      </g>
                      <g>
                          <path
                              d="M45.89,24.98c-0.35,0-0.68-0.18-0.87-0.5c-0.28-0.48-0.11-1.09,0.37-1.37l6-3.46c0.48-0.28,1.09-0.11,1.37,0.37       s0.11,1.09-0.37,1.37l-6,3.46C46.23,24.94,46.06,24.98,45.89,24.98z"/>
                      </g>
                      <g>
                          <path
                              d="M12.11,44.48c-0.35,0-0.68-0.18-0.87-0.5c-0.28-0.48-0.11-1.09,0.37-1.37l6-3.46c0.48-0.28,1.09-0.11,1.37,0.37       c0.28,0.48,0.11,1.09-0.37,1.37l-6,3.46C12.46,44.44,12.28,44.48,12.11,44.48z"/>
                      </g>
                  </g>
                  <g>
                      <g>
                          <circle cx="44.5" cy="10.35" r="3"/>
                      </g>
                      <g>
                          <circle cx="19.5" cy="53.65" r="3"/>
                      </g>
                      <g>
                          <path
                              d="M39.75,19.58c-0.17,0-0.34-0.04-0.5-0.13c-0.48-0.28-0.64-0.89-0.37-1.37l4-6.93c0.28-0.48,0.89-0.64,1.37-0.37       c0.48,0.28,0.64,0.89,0.37,1.37l-4,6.93C40.43,19.4,40.09,19.58,39.75,19.58z"/>
                      </g>
                      <g>
                          <path
                              d="M20.25,53.35c-0.17,0-0.34-0.04-0.5-0.13c-0.48-0.28-0.64-0.89-0.37-1.37l4-6.93c0.28-0.48,0.89-0.64,1.37-0.37       c0.48,0.28,0.64,0.89,0.37,1.37l-4,6.93C20.93,53.17,20.59,53.35,20.25,53.35z"/>
                      </g>
                  </g>
                  <g>
                      <g>
                          <circle cx="32" cy="7" r="3"/>
                      </g>
                      <g>
                          <circle cx="32" cy="57" r="3"/>
                      </g>
                      <g>
                          <path
                              d="M32,17c-0.55,0-1-0.45-1-1V9c0-0.55,0.45-1,1-1s1,0.45,1,1v7C33,16.55,32.55,17,32,17z"/>
                      </g>
                      <g>
                          <path
                              d="M32,56c-0.55,0-1-0.45-1-1v-7c0-0.55,0.45-1,1-1s1,0.45,1,1v7C33,55.55,32.55,56,32,56z"/>
                      </g>
                  </g>
                  <g>
                      <g>
                          <circle cx="19.5" cy="10.35" r="3"/>
                      </g>
                      <g>
                          <circle cx="44.5" cy="53.65" r="3"/>
                      </g>
                      <g>
                          <path
                              d="M24.27,19.61c-0.35,0-0.68-0.18-0.87-0.5l-4.04-7c-0.28-0.48-0.11-1.09,0.37-1.37c0.48-0.28,1.09-0.11,1.37,0.37       l4.04,7c0.28,0.48,0.11,1.09-0.37,1.37C24.61,19.57,24.44,19.61,24.27,19.61z"/>
                      </g>
                      <g>
                          <path
                              d="M43.75,53.35c-0.35,0-0.68-0.18-0.87-0.5l-4-6.93c-0.28-0.48-0.11-1.09,0.37-1.37c0.48-0.28,1.09-0.11,1.37,0.37       l4,6.93c0.28,0.48,0.11,1.09-0.37,1.37C44.09,53.31,43.92,53.35,43.75,53.35z"/>
                      </g>
                  </g>
                  <g>
                      <g>
                          <circle cx="10.35" cy="19.5" r="3"/>
                      </g>
                      <g>
                          <circle cx="53.65" cy="44.5" r="3"/>
                      </g>
                      <g>
                          <path
                              d="M18.11,24.98c-0.17,0-0.34-0.04-0.5-0.13l-6-3.46c-0.48-0.28-0.64-0.89-0.37-1.37c0.28-0.48,0.89-0.64,1.37-0.37       l6,3.46c0.48,0.28,0.64,0.89,0.37,1.37C18.79,24.8,18.46,24.98,18.11,24.98z"/>
                      </g>
                      <g>
                          <path
                              d="M51.89,44.48c-0.17,0-0.34-0.04-0.5-0.13l-6-3.46c-0.48-0.28-0.64-0.89-0.37-1.37c0.28-0.48,0.89-0.64,1.37-0.37       l6,3.46c0.48,0.28,0.64,0.89,0.37,1.37C52.57,44.3,52.23,44.48,51.89,44.48z"/>
                      </g>
                  </g>
                  <g>
                      <g>
                          <circle cx="32" cy="32" r="2"/>
                      </g>
                      <g>
                          <circle cx="37.25" cy="22.91" r="1.5"/>
                      </g>
                      <g>
                          <path
                              d="M32,15c-9.39,0-17,7.61-17,17s7.61,17,17,17s17-7.61,17-17S41.39,15,32,15z M34.22,21.16       c0.47-0.81,1.22-1.39,2.13-1.63c0.9-0.24,1.85-0.12,2.66,0.35c1.67,0.96,2.25,3.11,1.28,4.78c-0.47,0.81-1.22,1.39-2.13,1.63       c-0.3,0.08-0.61,0.12-0.91,0.12c-0.61,0-1.21-0.16-1.74-0.47C33.83,24.97,33.25,22.83,34.22,21.16z M25,19.88       c0.81-0.47,1.75-0.59,2.66-0.35c0.9,0.24,1.66,0.82,2.13,1.63c0.96,1.67,0.39,3.82-1.28,4.78c-0.54,0.31-1.14,0.47-1.74,0.47       c-0.3,0-0.61-0.04-0.91-0.12c-0.9-0.24-1.66-0.82-2.13-1.63C22.75,22.99,23.33,20.84,25,19.88z M18,32c0-1.93,1.57-3.5,3.5-3.5       S25,30.07,25,32s-1.57,3.5-3.5,3.5S18,33.93,18,32z M29.78,42.84c-0.47,0.81-1.22,1.39-2.13,1.63       c-0.3,0.08-0.61,0.12-0.91,0.12c-0.61,0-1.21-0.16-1.74-0.47c-1.67-0.96-2.25-3.11-1.28-4.78c0.47-0.81,1.22-1.39,2.13-1.63       c0.9-0.24,1.85-0.12,2.66,0.35C30.17,39.03,30.75,41.17,29.78,42.84z M28,32c0-2.21,1.79-4,4-4s4,1.79,4,4s-1.79,4-4,4       S28,34.21,28,32z M39,44.12c-0.54,0.31-1.14,0.47-1.74,0.47c-0.3,0-0.61-0.04-0.91-0.12c-0.9-0.24-1.66-0.82-2.13-1.63       c-0.96-1.67-0.39-3.82,1.28-4.78c0.81-0.47,1.75-0.59,2.66-0.35c0.9,0.24,1.66,0.82,2.13,1.63       C41.25,41.01,40.67,43.16,39,44.12z M42.5,35.5c-1.93,0-3.5-1.57-3.5-3.5s1.57-3.5,3.5-3.5S46,30.07,46,32       S44.43,35.5,42.5,35.5z"/>
                      </g>
                  </g>
              </g>
              <g>
                  <circle cx="26.75" cy="22.91" r="1.5"/>
              </g>
              <g>
                  <circle cx="21.5" cy="32" r="1.5"/>
              </g>
              <g>
                  <circle cx="26.75" cy="41.09" r="1.5"/>
              </g>
              <g>
                  <circle cx="37.25" cy="41.09" r="1.5"/>
              </g>
              <g>
                  <circle cx="42.5" cy="32" r="1.5"/>
              </g>
          </g>
      </g>
  </svg>
)

export default Logo
